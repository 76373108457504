import type { Customer } from "../../../../common/customer";

import { message } from "antd";
import { useForm } from "antd/es/form/Form";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import MyButton from "@/components/basic/button";
import MyInput from "@/components/basic/input";
import MySelect from "@/components/basic/select";
import MyForm from "@/components/core/form";
import { LocaleFormatter, useLocale } from "@/locales";
import { loadCustomersAsync } from "@/stores/customers.action";
import { deployBackendAsync } from "@/stores/website-chat.action";

type GoogleCloudLocations = {
  [region: string]: {
    displayName: string;
    id: string;
    supported: ("firestore" | "run")[];
  };
};

const DeployWebsiteChat = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { formatMessage } = useLocale();
  const dispatch = useDispatch();
  const [formInstance] = useForm();
  const [allowedLocations, setAllowedLocations] =
    useState<GoogleCloudLocations>({});
  const { customers } = useSelector((state) => state.customers);
  const [selectedCustomer, setSelectedCustomer] = useState<
    Customer.Info["_id"] | undefined
  >();

  const deploymentExists = !!state?.deployment;

  const fetchAllowedLocations = async () => {
    try {
      const response = await fetch("/google-cloud-locations.json");
      const locations = await response.json();

      setAllowedLocations(locations);
    } catch (err) {
      console.error(err);
    }
  };

  const deployBackend = async () => {
    const {
      customer: _,
      backendWebhookRepeatInterval,
      backendWebhookRepeatCount,
      ...formValues
    } = formInstance.getFieldsValue();

    const deployment = { ...formValues };

    if (selectedCustomer) {
      const customer = customers.find((c) => c._id === selectedCustomer);

      if (customer) {
        deployment.customer = {
          id: customer._id,
          instance: customer.instance,
        };
      }
    }

    if (backendWebhookRepeatCount) {
      deployment.backendWebhookRepeatCount = parseInt(
        backendWebhookRepeatCount,
        10
      );
    }

    if (backendWebhookRepeatInterval) {
      deployment.backendWebhookRepeatInterval = parseInt(
        backendWebhookRepeatInterval,
        10
      );
    }

    const success = await dispatch(deployBackendAsync(deployment));

    if (success) {
      message.success(formatMessage({ id: "websitechat.deployBackend.success" }))
      navigate("/channels/website-chat");
    }
  };

  const onCustomersearchDebounced = useCallback(
    debounce((search: string) => {
      dispatch(loadCustomersAsync({ search, limit: 50 }));
    }, 500),
    []
  );

  const onCustomerSearch = (search: string) => {
    if (search.length >= 2) {
      onCustomersearchDebounced(search);
    }
  };

  useEffect(() => {
    const formValues = formInstance.getFieldsValue();

    if (selectedCustomer && !formValues.gcpName) {
      const gcpName = selectedCustomer.startsWith("texter-")
        ? selectedCustomer
        : `texter-${selectedCustomer}`;

      formInstance.setFieldsValue({ gcpName });
    }
  }, [selectedCustomer]);

  useEffect(() => {
    fetchAllowedLocations();
  }, []);

  useEffect(() => {
    if (state?.deployment) {
      formInstance.setFieldsValue({
        customer: state.deployment.customer,
        gcpName: state.deployment.gcpName,
        imageTag: state.deployment.backend.imageTag,
        backendUrl: state.deployment.backend.url,
        gcpServiceLocation: state.deployment.backend.gcpServiceLocation,
        gcpFirestoreLocation: state.deployment.backend.gcpFirestoreLocation,
        backendWebhookRepeatInterval:
          state.deployment.backend.webhookRepeatInterval,
        backendWebhookRepeatCount: state.deployment.backend.webhookRepeatCount,
      });
    }
  }, [state]);

  return (
    <div className="deploy-container">
      <div className="title">
        <h1>
          <LocaleFormatter id="websiteChat.deploy.title" />
        </h1>
      </div>
      <div className="deploy-content">
        <MyForm
          layout="vertical"
          className="deploy-content-form"
          form={formInstance}
        >
          <MyForm.Item
            name="customer"
            style={{ order: deploymentExists ? "1" : "0" }}
            label={
              <LocaleFormatter id="websiteChat.table.header.customerOptional" />
            }
          >
            <MySelect
              value={selectedCustomer}
              onChange={setSelectedCustomer}
              onSearch={onCustomerSearch}
              options={customers.map((c) => ({
                label: c._id,
                value: c._id,
              }))}
              showSearch
              allowClear
            />
          </MyForm.Item>
          <MyForm.Item
            name="gcpName"
            label={<LocaleFormatter id="websiteChat.table.header.gcpName" />}
          >
            <MyInput />
          </MyForm.Item>
          <MyForm.Item
            name="imageTag"
            label={
              <LocaleFormatter id="websiteChat.table.header.backendVersion" />
            }
          >
            <MyInput />
          </MyForm.Item>
          <MyForm.Item
            name="gcpServiceLocation"
            label={
              <LocaleFormatter id="websiteChat.table.header.backendLocation" />
            }
          >
            <MySelect
              options={Object.entries(allowedLocations)
                .filter(([, location]) => location.supported.includes("run"))
                .map(([region, location]) => ({
                  label: `${location.displayName} (${region})`,
                  value: region,
                }))}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </MyForm.Item>
          <MyForm.Item
            name="gcpFirestoreLocation"
            label={
              <LocaleFormatter id="websiteChat.table.header.firestoreLocation" />
            }
          >
            <MySelect
              options={Object.entries(allowedLocations)
                .filter(([, location]) =>
                  location.supported.includes("firestore")
                )
                .map(([region, location]) => ({
                  label: `${location.displayName} (${region})`,
                  value: region,
                }))}
              optionFilterProp="label"
              disabled={deploymentExists}
              showSearch
              allowClear
            />
          </MyForm.Item>
          <MyForm.Item
            name="backendWebhookRepeatInterval"
            label={
              <LocaleFormatter id="websiteChat.table.header.backendWebhookRepeatIntervalOptional" />
            }
          >
            <MyInput type="number" />
          </MyForm.Item>
          <MyForm.Item
            name="backendWebhookRepeatCount"
            label={
              <LocaleFormatter id="websiteChat.table.header.backendWebhookRepeatCountOptional" />
            }
          >
            <MyInput type="number" />
          </MyForm.Item>
          <MyButton
            size="large"
            type="primary"
            htmlType="submit"
            onClick={() => deployBackend()}
            className="deploy-submit-button"
          >
            <LocaleFormatter id="global.deploy" />
          </MyButton>
        </MyForm>
      </div>
    </div>
  );
};

export default DeployWebsiteChat;
