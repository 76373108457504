export const enUS_globalTips = {
    'global.tips.notfound': 'Sorry, the page you visited does not exist.',
    'global.tips.unauthorized': 'Sorry, you are not authorized to access this page.',
    'global.tips.loginResult': 'When you see this page, it means you are logged in.',
    'global.tips.goToLogin': 'Go To Login',
    'global.tips.username': 'Username or Email',
    'global.tips.enterUsernameMessage': 'Please enter your Username!',
    'global.tips.password': 'Password',
    'global.tips.enterPasswordMessage': 'Please enter your Password!',
    'global.tips.rememberUser': 'Remember me',
    'global.tips.login': 'Login',
    'global.tips.logoutFailed': 'Logout failed. Please try again.',
    'global.tips.backHome': 'Back Home',
    'global.tips.operation': 'Operation',
    'global.tips.authorize': 'Authorize',
    'global.tips.delete': 'Delete',
    'global.tips.create': 'Create',
    'global.tips.modify': 'Modify',
    'global.tips.search': 'Search',
    'global.tips.reset': 'Reset',
    'global.tips.deleteConfirm': 'Do you Want to delete these items?',
    'global.tips.loading': 'Loading...',
    'global.tips.theme.darkTooltip': 'Switch to dark theme',
    'global.tips.theme.lightTooltip': 'Switch to light theme',
    'global.tips.theme.noticeTooltip': 'Notifications',

    'global.delete': 'Delete',
    'global.edit': 'Edit',
    'global.create': 'Create',
    'global.discard': 'Discard',
    'global.approve': 'Approve',
    'global.revoke': 'Revoke',
    'global.reject': 'Reject',
    'global.submit': 'Submit',
    'global.cancel': 'Cancel',
    'global.confirm': 'Confirm',
    'global.close': 'Close',
    'global.save': 'Save',
    'global.error': 'Error',
    'global.warning': 'Warning',
    'global.show': 'Show',
    'global.hide': 'Hide',
    'global.copy': 'Copy',
    'global.infininity': '∞',
    'global.never': 'Never',
    'global.none': 'None',
    'global.subscribe': 'Subscribe',
    'global.unsubscribe': 'Unsubscribe',
    'global.migrate': 'Migrate',
    'global.deploy': 'Deploy',
};
